<template>
    <div>
        <x-header>
            <img slot="headerImage" src="../../../assets/zhongeyunshu/banner.jpg" alt="">
            <h1 slot="headerTitle">{{ $store.state.navShow == true ? '中俄运输—MG集团可提供中俄两国间的国际物流服务':'Sino-Russian transportation - MG Group can provide international logistics between China and Russia'}}</h1>
        </x-header>
        <div style="position:relative;padding-bottom: 229px">
            <div class="zhongeyunshu_title">
                <p>{{$store.state.navShow == true ? 'MG集团在俄罗斯本土拥有强大的铁路运输资源优势，运输网络遍布俄罗斯，在海运方面有十余年运营经验；在俄罗斯汽车陆运市场占有重要地位，拥有300余辆皮卡厢式货车，可提供“站到站”“门到门”服务；空运方面提供“石家庄—莫斯科”货运包机专线；专业化的服务团队熟悉本国政策及操作流程，可提供中俄两国国际物流的一站式服务。':'MG Group has a strong advantage in railway transportation resources in Russia, and its transportation network covers all over Russia. It has more than ten years of operation experience in shipping. It occupies an important position in the Russian automobile land transport market, with more than 300 pickup vans, which can provide "station to station" and "door to door" services.In Air transport, it owns "Shijiazhuang - Moscow" cargo charter line; In service, it has professional service team who are familiar with the national policy and operation process, can provide China and Russia international logistics one-stop service.'}}</p>
            </div>
            <div class="imgshow" data-aos="zoom-in-down">
                <img v-if="$store.state.navShow == true" src="../../../assets/zhongeyunshu/bannerCenter.jpg" alt="">
                <img v-else src="../../../assets/zhongeyunshu/bannerCenterE.jpg" alt="">
            </div>
            <div class="footer_img">
                <img src="../../../assets/zhongeyunshu/bottomBanner.png" alt="">
            </div>
        </div>

    </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  }

}
</script>
<style lang="less" scoped>
    .zhongeyunshu_title{
        width: var(--width1200px);
        margin: 110px auto 50px;
        font-family: Microsoft YaHei;
        color: #666666;
        text-indent: 32px;
        text-align: left;
        line-height: 35px;
    }
    .imgshow{
        margin-bottom:-58px;
    }
    .footer_img{
        // height: 243px;
        width: 100%;
        position: absolute;
        bottom: 0;
        & > img{
            width: 72%;
        }
    }
</style>
